<template>
    <e-iframe-page :loading="loading" ref="iframePage" :title="'Create Website|Créer un site'|tr" class="mx-auto text-left" style="max-width: 600px;">
        <template v-if="newWebsite">
            <tab-header v-model="newWebsite.name" :icon="getPublicIcon('window')">
                <template #input>
                    <b-form-group class="ml-3" :label="'Website Name|Nom du site internet'|tr">
                        <e-input type="name" v-model="newWebsite.name"/>
                        <div class="form-help mt-2" v-tr>
                            Name is for internal use only|Le nom du site est pour usage interne seulement
                        </div>
                    </b-form-group>
                </template>
            </tab-header>

            <!--                        <b-form-group :disabled="!dealerIds || dealerIds.length <= 1">-->
            <!--                            <label v-tr>Venue (required)|Compte (requis)</label>-->
            <!--                            <vue-multiselect v-model="newWebsite.dealerid" placeholder="Choose a dealer" :allowEmpty="false"-->
            <!--                                             :options="dealerIds">-->
            <!--                            </vue-multiselect>-->
            <!--                        </b-form-group>-->

            <b-card class="simple mt-3">
                <b-form-group :label="'Site Title|Titre du site'|tr">
                    <e-translation-input v-model="newWebsite.meta.title"/>
                    <div class="form-help mt-2" v-tr>
                        Your site title appears in your browser tab, along with your favicon.|
                        Le titre de votre site apparaît dans l'onglet du navigateur, à côté du favicon.
                    </div>
                </b-form-group>

                <b-form-group :label="'Domain Name|Nom de domaine'|tr">
                    <b-input-group>
                        <b-input-group-text>https://</b-input-group-text>
                        <e-input type="text" v-model="newWebsite.domain"/>
                    </b-input-group>
                </b-form-group>
            </b-card>
        </template>

        <template #footer="{close, refresh}">
            <b-btn variant="primary" @click="create()" :disabled="!isValid">
                <span v-tr>Create|Créer</span>
            </b-btn>
        </template>
    </e-iframe-page>
</template>

<script>
import SelectCmsItemType from "@/components/editor/properties/inputs/select-cms-item-type.vue";
import CmsPage from "@/helpers/cms/CmsPage.js";
import TabHeader from "@/layout/tab-header.vue";
import NetworkV2 from "@/../vue-components/helpers/NetworkV2.js";
import ETranslationInput from "@/../vue-components/components/e-translation-input.vue";
import EIframePage from "@/../vue-components/components/e-iframe-page.vue";
import NewWebsiteTemplate from "@/assets/json/new-website-template.json"
import EInput from "../../vue-components/components/e-input.vue";

// iframe-website-creation #hot-reload-debug
export default {
    name: `iframe-website-creation`,
    components: {EInput, SelectCmsItemType, ETranslationInput, TabHeader, EIframePage},
    data() {
        return {
            loading: false,
            newWebsite: null,
            homePage: null
        }
    },
    computed: {
        isValid() {
            if (!this.newWebsite)
                return false;

            return (this.newWebsite.name && this.newWebsite.domain);
        },
        dealerIds() {
            return this.$store.state.dealers.map(dealer => {
                return dealer.dealerid;
            });
        }
    },
    mounted() {
        this.newWebsite = clone(NewWebsiteTemplate);
        this.newWebsite.dealerid = this.$route.params.dealerid;

        this.homePage = new CmsPage();
        this.homePage.name = {en: `Home`, fr: `Accueil`};
        this.homePage.permalink = {en: `/`, fr: `/`};
        this.homePage.glyph = `fa-house`;
        this.homePage.homePage = true;
        this.homePage.dealerid = this.newWebsite.dealerid;
    },
    methods: {
        create() {
            if (!this.isValid) {
                this.showInvalidFields();
                return false;
            }
            this.showLoading();

            NetworkV2.post(`/api/websites/create`, {
                website: this.newWebsite,
                home: this.homePage
            })
                .then(resp => {
                    this.showSavedMessage(`Created|Créé`, `Website created successfully|Site internet créé avec succès`);

                    this.$refs.iframePage.created(resp.data);
                });
        }
    }
}
</script>

<style scoped>

</style>
